import { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useCsrfToken } from "@hooks/useCsrfToken";
import SingleWorkspaceModel from "@/Workspace/Model/SingleWorkspaceModel";
import useUser from "@hooks/user/useUser";
import { useWorkspaces } from "@components/Workspace/hooks/useWorkspaces";
import UserModel from "@/Core/Models/User/UserModel";
import { System } from "@design-system/index";
import { isNil } from "lodash";

interface Props {
    workspace: SingleWorkspaceModel;
}

const renderLogo = ({ workspace }: { workspace: SingleWorkspaceModel }) => {
    if (workspace?.logo) {
        return (
            <img
                src={workspace.logo}
                className="h-6 w-6 rounded-sm object-cover"
                alt={workspace.name}
            />
        );
    }

    return (
        <span className="w-6 h-6 bg-gray-200 text-xs rounded-sm font-bold text-center flex items-center justify-center">
            {workspace?.name.substring(0, 2).toUpperCase()}
        </span>
    );
};

const WorkspaceMenuItem = ({ workspace }: Props) => {
    const formRef = useRef(null);
    const csrfToken = useCsrfToken();

    const onClick = () => {
        formRef.current.submit();
    };

    return (
        <Menu.Item>
            <div
                className="flex items-center gap-2 cursor-pointer px-2 py-1.5 hover:bg-slate-50 transition rounded-md"
                onClick={onClick}
            >
                {renderLogo({ workspace })}
                <System.Text>{workspace?.name}</System.Text>
                <System.Badges.Information color="gray" className="ml-auto">
                    <System.Text as="span" size="small">
                        {workspace?.total_projects}
                    </System.Text>
                </System.Badges.Information>
                <form
                    action="/api/auth/callback/credentials_team_member"
                    method="post"
                    ref={formRef}
                >
                    <input
                        name="csrfToken"
                        type="hidden"
                        defaultValue={csrfToken}
                    />
                    <input
                        type="hidden"
                        value={workspace?.user_id}
                        name="user_id"
                    />
                    <input type="hidden" value="/projects" name="callbackUrl" />
                </form>
            </div>
        </Menu.Item>
    );
};

const WorkspaceCardItem = ({ workspace }: Props) => {
    const formRef = useRef(null);
    const csrfToken = useCsrfToken();

    const onClick = () => {
        formRef.current.submit();
    };

    return (
        <div
            className="border flex flex-col items-center justify-center gap-2 cursor-pointer p-4 hover:bg-slate-50 transition rounded-md"
            onClick={onClick}
        >
            {renderLogo({ workspace })}
            <System.Text>{workspace?.name}</System.Text>
            <System.Badges.Information color="gray">
                <System.Text as="span" size="small">
                    {workspace?.total_projects} Site
                    {workspace?.total_projects > 1 && "s"}
                </System.Text>
            </System.Badges.Information>
            <form
                action="/api/auth/callback/credentials_team_member"
                method="post"
                ref={formRef}
            >
                <input
                    name="csrfToken"
                    type="hidden"
                    defaultValue={csrfToken}
                />
                <input
                    type="hidden"
                    value={workspace?.user_id}
                    name="user_id"
                />
                <input type="hidden" value="/projects" name="callbackUrl" />
            </form>
        </div>
    );
};

export const WorkspaceMenu = (props?: { variant?: "menu" | "card" }) => {
    const { user } = useUser({
        options: {
            withModel: true,
        },
    });

    const { variant = "menu" } = props;

    const formRef = useRef(null);
    const csrfToken = useCsrfToken();

    const { data: workspaces } = useWorkspaces();
    const [needToRedirect, setNeedToRedirect] = useState(false);

    // Get and remove my workspace
    const personalWorkspace = workspaces.find((workspace) => {
        return workspace.user_id === (user as UserModel).id;
    });

    const currentWorkspace = workspaces.find((workspace) => {
        return workspace.user_id === (user as UserModel).id;
    });

    const currentWorkspaceName =
        currentWorkspace?.name ||
        `${(user as UserModel).firstname}'s workspace`;

    // Security if you lose access to a workspace
    useEffect(() => {
        if (workspaces.length > 1) {
            return;
        }
        if (!isNil(currentWorkspace)) {
            return;
        }

        if (workspaces.length === 0) {
            return;
        }

        setNeedToRedirect(true);
    }, [currentWorkspace]);

    useEffect(() => {
        if (!needToRedirect) {
            return;
        }

        formRef.current.submit();
    }, [needToRedirect]);

    return (
        <>
            {needToRedirect && (
                <form
                    action="/api/auth/callback/credentials_team_member"
                    method="post"
                    ref={formRef}
                >
                    <input
                        name="csrfToken"
                        type="hidden"
                        defaultValue={csrfToken}
                    />
                    <input
                        type="hidden"
                        value={workspaces[0]?.user_id}
                        name="user_id"
                    />
                    <input type="hidden" value="/projects" name="callbackUrl" />
                </form>
            )}
            {variant === "menu" && (
                <Menu as="div" className="relative">
                    <Menu.Button className="flex items-center gap-2 group hover:bg-indigo-50 transition p-1 rounded justify-between px-2 py-2 w-full">
                        {renderLogo({ workspace: currentWorkspace })}
                        <System.Text className="text-sm font-medium text-left">
                            {currentWorkspaceName.length > 12 ? (
                                <>{currentWorkspaceName.substring(0, 12)}...</>
                            ) : (
                                currentWorkspaceName
                            )}
                        </System.Text>
                        <System.Svg.ChevronDown className="w-4 h-4" />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-50"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-left absolute left-0 mt-2 w-64 rounded-md shadow-md py-1 px-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                            <div className="px-1 py-1 mt-1">
                                <System.Text size="small">
                                    My Workspace
                                </System.Text>
                            </div>
                            <WorkspaceMenuItem workspace={personalWorkspace} />
                            <div className="px-1 py-1 mt-1">
                                <System.Text size="small">
                                    Shared Workspaces
                                </System.Text>
                            </div>
                            {workspaces.length === 1 && (
                                <div className="px-1 py-1">
                                    <System.Text>
                                        No other workspace available
                                    </System.Text>
                                </div>
                            )}
                            {workspaces.map((workspace) => {
                                if (
                                    workspace.user_id === (user as UserModel).id
                                ) {
                                    return null;
                                }
                                return (
                                    <WorkspaceMenuItem
                                        key={workspace.id}
                                        workspace={workspace}
                                    />
                                );
                            })}
                        </Menu.Items>
                    </Transition>
                </Menu>
            )}
            {variant === "card" && (
                <div className="grid grid-cols-2 gap-8">
                    {workspaces.map((workspace) => {
                        if (workspace.user_id === (user as UserModel).id) {
                            return null;
                        }
                        return (
                            <WorkspaceCardItem
                                key={workspace.id}
                                workspace={workspace}
                            />
                        );
                    })}
                    <WorkspaceCardItem workspace={personalWorkspace} />
                </div>
            )}
        </>
    );
};
